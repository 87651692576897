import React from "react";

const Poster = () => {
  return (
    <div className="w-full h-screen ">
      <div
        className="w-full md:block hidden h-screen"
        onClick={() => {
          window.open("https://wa.link/adsmeta1", "_blank");
        }}
        style={{
          backgroundImage: "url('/zaid poster.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <button className="text-white px-10  animate-bounce absolute bottom-10 left-32 py-3 border-2 border-white  rounded-md  bg-transparent  text-lg md:text-xl uppercase font-bold">
          Claim now
        </button>
      </div>

      <div className="w-full md:hidden  block   ">
        <div
          className="w-full h-screen"
          style={{
            backgroundImage: "url('/bg red.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className=" pt-5 items-center ">
            <div className="ml-7 -space-y-7">
              <h1 className="text-2xl font-bold uppercase  text-white font-mosntaratbold ">
                play and win
              </h1>
              <h1 className=" font-monstratitalic text-[10rem] text-red-600">
                10%
              </h1>
              <p className="text-[4rem] text-white uppercase font-mosntaratbold">
                {" "}
                B0nu$
              </p>
              <h2 className="text-white text-[4rem] uppercase font-mosntaratbold">
                Welcome
              </h2>
              <h2 className="text-3xl font-mosntaratbold uppercase text-red-600">
                3% <span className="text-white">B0nus on every refill</span>
              </h2>
              <button
                onClick={() => {
                  window.open("https://wa.link/adsmeta1", "_blank");
                }}
                className="text-white px-6  animate-bounce absolute bottom-[24vh] left-8 py-2 border-2 border-white  rounded-md  bg-transparent  text-lg md:text-xl uppercase font-bold"
              >
                Claim now
              </button>
            </div>

            <div className="w-full h-[40vh] mt-[50px]">
              <img
                className=" w-full h-full object-contain"
                src="/ladki.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Poster;
